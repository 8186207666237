var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import clsx from "clsx";
import { getListItemKeyword, translate } from "../../FormGenerator";
import { RequiredFieldDesignation } from "../../utils/RequiredFieldDesignation";
import { ErrorBlock } from "../../../error/ErrorBlock";
import { translater } from "../../../../../../utils/localization/localization";
import { Hint } from "../../utils/Hint";
import { SaveAudioArea } from "./components/SaveAudioArea";
import { API } from "../../../../../../api";
import { Skeleton } from "primereact/skeleton";
export var Audio = function (_a) {
    var _b = _a.form, register = _b.register, getValues = _b.getValues, setValue = _b.setValue, trigger = _b.trigger, errors = _b.formState.errors, _c = _a.field, titleTranslaterKey = _c.titleTranslaterKey, field = _c.field, placeholderTranslaterKey = _c.placeholderTranslaterKey, required = _c.required, hintTranslaterKey = _c.hintTranslaterKey, staticList = _c.staticList, _d = _c.apiDataUrl, apiDataUrl = _d === void 0 ? '/builder/get-audio-list' : _d;
    var ref = useRef(null);
    var _e = __read(useState(false), 2), audioAreaVisible = _e[0], setAudioAreaVisible = _e[1];
    var _f = __read(useState(false), 2), loading = _f[0], setLoading = _f[1];
    var _g = __read(useState([]), 2), audioList = _g[0], setAudioList = _g[1];
    var rules = {};
    if (required)
        rules.required = translater("Form Generator Required Field").toString();
    var params = {};
    if (placeholderTranslaterKey)
        params.placeholder = translate(placeholderTranslaterKey).toString();
    var loadAudio = function () {
        setLoading(true);
        API.getServerData(apiDataUrl)
            .then(function (response) {
            var data = response.data;
            setAudioList(data);
            setLoading(false);
        })
            .finally(function () { return setLoading(false); })
            .catch(function () { return setLoading(false); });
    };
    useEffect(function () {
        if (apiDataUrl !== 'no-key')
            loadAudio();
        if (staticList)
            setAudioList(staticList);
    }, []);
    useEffect(function () { trigger(field); }, [getValues(field)]);
    return (_jsxs("div", __assign({ className: "w-full flex flex-column align-items-start gap-2" }, { children: [_jsxs("div", __assign({ className: "w-full flex flex-column" }, { children: [_jsxs("span", __assign({ className: "mb-1" }, { children: [hintTranslaterKey && (_jsx("i", { onClick: function (e) { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "text-primary p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" })), translate(titleTranslaterKey), required && _jsx(RequiredFieldDesignation, {})] })), loading
                        ? _jsx(Skeleton, { width: "100%", height: "45px" })
                        : (_jsxs("div", __assign({ className: "flex gap-2 mb-2" }, { children: [_jsx(Dropdown, __assign({}, params, { options: audioList, optionLabel: "keyword", value: getListItemKeyword(getValues(field)), valueTemplate: getListItemKeyword(getValues(field)), itemTemplate: function (option) { return getListItemKeyword(option); }, onChange: function (e) {
                                        setValue(field, e.value);
                                        setTimeout(function () { return trigger(field); }, 0);
                                    }, className: "w-full" })), _jsx(Button, { onClick: function () { return setAudioAreaVisible(!audioAreaVisible); }, className: clsx('p-button-outlined', {
                                        "border-green-600 text-green-600": !audioAreaVisible,
                                        "p-button-secondary": audioAreaVisible
                                    }), icon: clsx('pi', {
                                        "pi-plus": !audioAreaVisible,
                                        "pi-times": audioAreaVisible
                                    }), type: "button" })] }))), audioAreaVisible
                        && _jsx(SaveAudioArea, { refresh: loadAudio, hideAudioArea: function () { return setAudioAreaVisible(false); } })] })), _jsx(ErrorBlock, { errors: errors, keyword: field }), _jsx(Hint, { hint: hintTranslaterKey, reference: ref })] })));
};
