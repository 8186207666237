var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from 'primereact/dialog';
import { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { API } from '../../../../../../../../api';
import { successNotification } from '../../../../../../../../redux/actions/notificationsActions';
import { CreateUserModal } from '../../../../users/components/header/components/CreateUserModal';
import { CreateModal as CreateAudioModal } from '../../../../audio/components/header/components/CreateModal';
import { translater } from '../../../../../../../../utils/localization/localization';
import { FilterMatchMode } from 'primereact/api';
import { transfortDuration } from '../../../../audio/components/list/components/columns/components/duration';
import { defaultAWSAudioLazyState } from '../../../../audio/components/list/AudioList';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { dynamicColumns } from '../../../../users/components/list/components/columns/columns';
var defaultGroup = {
    name: '',
    globalName: '',
    users: [],
    initiators: [],
    audio: null,
    context: null
};
var defaultUsersLazyState = {
    first: 0,
    page: 1,
    filters: {
        activeDirectoryId: { value: null, matchMode: FilterMatchMode.IN }
    }
};
export var CreateModal = function (_a) {
    var hide = _a.hide, setFinder = _a.setFinder, refresh = _a.refresh;
    var _b = __read(useState(defaultGroup), 2), group = _b[0], setGroup = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var globalNameRef = useRef(null);
    var initiatorsRef = useRef(null);
    // Initiators
    var _d = __read(useState(''), 2), initiators = _d[0], setInitiators = _d[1];
    // Contexts
    var _e = __read(useState([]), 2), contexts = _e[0], setContexts = _e[1];
    var _f = __read(useState(false), 2), contextsLoading = _f[0], setContextsLoading = _f[1];
    // Users 
    var _g = __read(useState([]), 2), users = _g[0], setUsers = _g[1];
    var _h = __read(useState({}), 2), expandedUsersGroups = _h[0], setExpandedUsersGroups = _h[1];
    var _j = __read(useState(false), 2), createUserModalVisible = _j[0], setCreateUserModalVisible = _j[1];
    var _k = __read(useState([]), 2), selectedUsers = _k[0], setSelectedUsers = _k[1];
    var _l = __read(useState(50), 2), usersLimit = _l[0], setUsersLimit = _l[1];
    var _m = __read(useState(0), 2), usersTableItemsAmount = _m[0], setUsersTableItemsAmount = _m[1];
    var _o = __read(useState(defaultUsersLazyState), 2), lazyState = _o[0], setLazyState = _o[1];
    var _p = __read(useState(''), 2), globalUsersFinderValue = _p[0], setGlobalUsersFinderValue = _p[1];
    // Audio
    var _q = __read(useState([]), 2), Audios = _q[0], setAudios = _q[1];
    var _r = __read(useState(false), 2), createAudioModalVisible = _r[0], setCreateAudioModalVisible = _r[1];
    var toggleUsersGroups = function (event, userName) {
        event.preventDefault();
        event.stopPropagation();
        setExpandedUsersGroups(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[userName] = !prevState[userName], _a)));
        });
    };
    var handleUserSearch = function (value) {
        setGlobalUsersFinderValue(value);
    };
    var loadUsers = function () {
        setLoading(true);
        API.finderAWSUser(globalUsersFinderValue.trim(), lazyState)
            .then(function (response) {
            var _a = response.data, amount = _a.amount, users = _a.users, limit = _a.limit;
            setUsersLimit(limit);
            setUsersTableItemsAmount(amount);
            setUsers(users);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var loadAudios = function () {
        setLoading(true);
        API.finderAWSAudio('', defaultAWSAudioLazyState)
            .then(function (response) {
            var audios = response.data.audios;
            setAudios(audios);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var loadContexts = function () {
        setContextsLoading(true);
        var defaultLazyState = {
            first: 0,
            page: 1,
            filters: {
                activeDirectoryId: { value: null, matchMode: FilterMatchMode.IN }
            }
        };
        API.contextsFinder('', defaultLazyState)
            .then(function (response) {
            var contexts = response.data.contexts;
            setContexts(contexts);
            setContextsLoading(false);
        })
            .catch(function () { return setContextsLoading(false); });
    };
    var handleAudioSearch = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var allAudio, audio_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API.finderAWSAudio('', defaultAWSAudioLazyState)];
                case 1:
                    allAudio = _a.sent();
                    audio_1 = allAudio.data.audios.find(function (audio) { return audio.name === value; });
                    setGroup(function (prev) { return (__assign(__assign({}, prev), { audio: audio_1 })); });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    setGroup(function (prev) { return (__assign(__assign({}, prev), { audio: null })); });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var loadData = function () {
        loadUsers();
        loadAudios();
        loadContexts();
    };
    useEffect(function () {
        loadData();
    }, []);
    useEffect(function () {
        var timeOut = setTimeout(function () { return loadUsers(); }, 500);
        return function () { return clearTimeout(timeOut); };
    }, [globalUsersFinderValue, lazyState]);
    var create = function () {
        setLoading(true);
        var groupClone = JSON.parse(JSON.stringify(group));
        groupClone.users = selectedUsers.map(function (user) { return user._id; });
        var initiatorsList = initiators
            .split(',')
            .map(function (_) { return _.trim(); });
        // Убираем клоны
        groupClone.initiators = __spreadArray([], __read(new Set(initiatorsList)), false);
        groupClone.context = groupClone.context._id;
        API.createAWSGroup(groupClone)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            hide();
            refresh();
            // setFinder(group.name)
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var validation = function () {
        if (initiators.trim().length === 0)
            return true;
        if (group.name.trim() === '')
            return true;
        if (group.globalName.trim() === '')
            return true;
        if (!group.audio)
            return true;
        if (!group.context)
            return true;
        return false;
    };
    var onPage = function (event) { setLazyState(event); };
    var onFilter = function (event) {
        event['first'] = 0;
        setLazyState(event);
    };
    var audioTemplate = function (audio) {
        if (!audio)
            return translater("awsGroupsItemCreateModalAudioFieldPlaceholder");
        return (_jsxs("div", __assign({ className: "flex align-items-center justify-content-between" }, { children: [_jsx("span", { children: audio.name }), _jsxs("span", __assign({ className: "text-600 text-sm" }, { children: [transfortDuration(audio.duration), " ", translater('awsAudioListSeconds')] }))] })));
    };
    var groupsTemplate = function (user) {
        var _a, _b;
        var showAllGroups = expandedUsersGroups[user.name];
        if (((_a = user.groups) === null || _a === void 0 ? void 0 : _a.length) === 0)
            return '–';
        return ((_b = user.groups) === null || _b === void 0 ? void 0 : _b.length) ? (_jsxs("div", __assign({ className: "w-full flex align-items-center justify-content-center gap-2 flex-wrap" }, { children: [showAllGroups
                    ? user.groups.map(function (group, index) { return (_jsx("span", __assign({ className: "border-1 p-1 border-round-sm border-300 text-600 text-sm" }, { children: group.name }), group.name + index)); })
                    : user.groups.slice(0, 3).map(function (group, index) { return (_jsx("span", __assign({ className: "border-1 p-1 border-round-sm border-300 text-600 text-sm" }, { children: group.name }), group.name + index)); }), user.groups.length > 3 && (_jsx("span", __assign({ className: "p-1 border-round-sm border-300 text-600 text-sm border-1", onClick: function (e) { return toggleUsersGroups(e, user.name); } }, { children: showAllGroups ?
                        _jsx("i", { className: "pi pi-times text-600 text-sm" })
                        : '...' })))] }))) : ("");
    };
    var columns = dynamicColumns.map(function (column) {
        var header = translater(column.columnHeader);
        if (column.field === 'groups') {
            return _jsx(Column, __assign({ body: groupsTemplate, className: "justify-content-center px-5", header: header, style: column.style || { minWidth: "100px" } }, column), column.field);
        }
        return (_jsx(Column, __assign({ className: "justify-content-center px-5", header: header, style: column.style || { minWidth: "100px" } }, column), column.field));
    });
    var handleInput = function (e) {
        // Разрешённые символы: цифры, запятая, пробел
        var regex = /^[0-9, ]*$/;
        console.log(regex.test(e.target.value));
        if (!regex.test(e.target.value)) {
            // Блокируем запрещённый ввод
            e.preventDefault();
        }
    };
    return (_jsxs(Dialog, __assign({ visible: true, header: translater("awsGroupsItemCreateModalDialogHeader"), style: { width: '50vw', height: '100%' }, onHide: function () { return hide(); } }, { children: [_jsxs("div", __assign({ className: 'flex flex-column gap-3' }, { children: [_jsxs("div", __assign({ className: 'flex flex-column gap-1' }, { children: [_jsx("label", { children: translater("awsGroupsItemCreateModalNameField") }), _jsx(InputText, { value: group.name, placeholder: translater("awsGroupsItemCreateModalNameFieldPlaceholder").toString(), onChange: function (e) { return setGroup(function (prev) { return (__assign(__assign({}, prev), { name: e.target.value })); }); } })] })), _jsx(OverlayPanel, __assign({ ref: globalNameRef }, { children: translater("awsGroupsItemCreateModalSystemNameFieldHint") })), _jsxs("div", __assign({ className: 'flex flex-column gap-1' }, { children: [_jsxs("label", { children: [_jsx("i", { onClick: function (e) { var _a; return (_a = globalNameRef.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" }), translater("awsGroupsItemCreateModalSystemNameField")] }), _jsx(InputText, { onChange: function (event) {
                                    var value = event.target.value.toUpperCase();
                                    var regex = /^[A-Z0-8_]*$/;
                                    console.log(regex.test(value));
                                    if (regex.test(value)) {
                                        setGroup(function (prev) { return (__assign(__assign({}, prev), { globalName: event.target.value })); });
                                    }
                                }, value: group.globalName, placeholder: translater("awsGroupsItemCreateModalSystemNameFieldPlaceholder").toString() })] })), _jsx(OverlayPanel, __assign({ ref: initiatorsRef }, { children: translater("awsGroupsItemCreateInitiatorsNameFieldHint") })), _jsxs("div", __assign({ className: 'flex flex-column gap-1' }, { children: [_jsxs("label", { children: [_jsx("i", { onClick: function (e) { var _a; return (_a = initiatorsRef.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" }), translater("awsGroupsItemCreateModalInitiatorsField")] }), _jsx(InputText, { value: initiators, onChange: function (event) {
                                    var value = event.target.value;
                                    var regex = /^[0-9, ]*$/;
                                    if (regex.test(value)) {
                                        setInitiators(value);
                                    }
                                }, className: "w-full", placeholder: translater("awsGroupsItemCreateModalInitiatorsFieldPlaceholder").toString() })] })), _jsxs("div", __assign({ className: 'flex flex-column gap-2' }, { children: [_jsx("label", { children: translater("awsGroupsItemCreateModalUsersField") }), _jsxs("div", __assign({ className: "flex align-items-center gap-2" }, { children: [_jsx(InputText, { value: globalUsersFinderValue, className: "w-full", placeholder: translater("awsGroupsItemCreateModalSearchByUsers").toString(), onChange: function (e) { return handleUserSearch(e.target.value); } }), _jsx(Button, { onClick: function () { return setCreateUserModalVisible(true); }, icon: "pi pi-plus", className: 'p-button-success' })] })), _jsxs(DataTable, __assign({ lazy: true, paginator: true, value: users, loading: loading, rows: usersLimit, first: lazyState.first, totalRecords: usersTableItemsAmount, onPage: onPage, onFilter: onFilter, filters: lazyState.filters, responsiveLayout: "scroll", scrollable: true, selection: selectedUsers, onSelectionChange: function (e) { return setSelectedUsers(e.value); }, className: "border-1 border-300 border-round-md overflow-hidden", dataKey: "_id" }, { children: [_jsx(Column, { className: "flex justify-content-center", selectionMode: "multiple", style: { maxWidth: "90px" }, headerStyle: { width: '6rem', height: "65px" } }, 'selecting'), columns] }))] })), _jsxs("div", __assign({ className: 'flex flex-column gap-2' }, { children: [_jsx("label", { children: translater("awsGroupsItemCreateModalContextField") }), _jsx(Dropdown, { filter: true, disabled: Boolean(contextsLoading), value: group.context, onChange: function (e) { return setGroup(function (prev) {
                                    return (__assign(__assign({}, prev), { context: e.target.value }));
                                }); }, options: contexts, optionLabel: "name", placeholder: translater("awsGroupsItemCreateModalContextFieldPlaceholder").toString(), className: "w-full" })] })), _jsxs("div", __assign({ className: 'flex flex-column gap-2' }, { children: [_jsx("label", { children: translater("awsGroupsItemCreateModalAudioField") }), _jsxs("div", __assign({ className: "flex align-items-center gap-2" }, { children: [_jsx(Dropdown, { filter: true, itemTemplate: audioTemplate, valueTemplate: audioTemplate, value: group.audio, onChange: function (e) { return setGroup(function (prev) {
                                            return (__assign(__assign({}, prev), { audio: e.target.value }));
                                        }); }, options: Audios, optionLabel: "name", placeholder: translater("awsGroupsItemCreateModalAudioFieldPlaceholder").toString(), className: "w-full" }), _jsx(Button, { onClick: function () { return setCreateAudioModalVisible(true); }, icon: "pi pi-plus", className: 'p-button-success' })] }))] })), _jsx(Button, { disabled: validation(), loading: loading, onClick: function () { return create(); }, label: translater("awsGroupsItemCreateModalCreateButton").toString(), className: 'w-full p-button-success' })] })), createUserModalVisible ? (_jsx(CreateUserModal, { refresh: function () { return loadUsers(); }, setFinder: handleUserSearch, hide: function () { return setCreateUserModalVisible(false); } })) : '', createAudioModalVisible ? (_jsx(CreateAudioModal, { refresh: function () { return loadAudios(); }, setFinder: handleAudioSearch, hide: function () { return setCreateAudioModalVisible(false); } })) : ''] })));
};
