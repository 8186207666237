var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import cloneDeep from 'lodash/cloneDeep';
export var clone = function (content) { return cloneDeep(content); };
// Ищет элемент внутри дерева по id
export var findTreeElementById = function (tree, id) {
    var e_1, _a;
    if (tree.id === id)
        return clone(tree);
    if (tree.children) {
        try {
            // Если у текущего узла есть дочерние элементы, рекурсивно вызываем функцию для каждого из них
            for (var _b = __values(tree.children), _c = _b.next(); !_c.done; _c = _b.next()) {
                var child = _c.value;
                var foundElement = findTreeElementById(child, id);
                if (foundElement) {
                    return clone(foundElement);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    return null;
};
// Ищет элемент внутри дерева по id
export var findTreeElementBy_Id = function (tree, id) {
    var e_2, _a;
    if (tree._id === id)
        return clone(tree);
    if (tree.children) {
        try {
            // Если у текущего узла есть дочерние элементы, рекурсивно вызываем функцию для каждого из них
            for (var _b = __values(tree.children), _c = _b.next(); !_c.done; _c = _b.next()) {
                var child = _c.value;
                var foundElement = findTreeElementBy_Id(child, id);
                if (foundElement) {
                    return clone(foundElement);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    return null;
};
// Ищет родетеля элемента с id
export var findParentElementById = function (tree, id, parent) {
    var e_3, _a;
    if (parent === void 0) { parent = null; }
    if (tree.id === id)
        return clone(parent);
    if (tree.children) {
        try {
            // Если у текущего узла есть дочерние элементы, рекурсивно вызываем функцию для каждого из них
            for (var _b = __values(tree.children), _c = _b.next(); !_c.done; _c = _b.next()) {
                var child = _c.value;
                var foundParent = findParentElementById(child, id, tree);
                if (foundParent) {
                    return clone(foundParent);
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
    }
    return null;
};
// Заменяет элемент внутри дерева по id
export var replaceElementById = function (tree, id, newElement) {
    if (tree.id === id)
        return clone(newElement);
    if (tree.children) {
        // Если у текущего узла есть дочерние элементы, рекурсивно вызываем функцию для каждого из них
        var newChildren = tree.children.map(function (child) { return replaceElementById(child, id, newElement); });
        return __assign(__assign({}, tree), { children: newChildren });
    }
    return clone(tree);
};
// Возвращает масиив id всех потомков (и потомков самих потомков) по id элемента
export function findAllChildrenIds(tree, id) {
    var result = [];
    function traverse(node) {
        var e_4, _a;
        if (node.id === id) {
            // Если мы находим искомый идентификатор, проходимся по всем детям
            var collectAllChildIds_1 = function (childNode) {
                result.push(childNode.id);
                childNode.children.forEach(collectAllChildIds_1);
            };
            node.children.forEach(collectAllChildIds_1);
            return;
        }
        try {
            // Иначе продолжаем поиск по дереву
            for (var _b = __values(node.children), _c = _b.next(); !_c.done; _c = _b.next()) {
                var child = _c.value;
                traverse(child);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
    }
    traverse(tree);
    return result;
}
// Ищем все id элементов на которые можем перетягивать ветки
export var findDroppableNodes = function (tree) {
    var result = [];
    var permition = function (node) {
        if (node.canHaveChildren) {
            // Разрешаем вешать на одночные элементы без children
            if (!node.isChildrenList && node.children.length === 0)
                return true;
            // Разрешаем вешать на множественные элементы в которых не хватает children
            if (node.isChildrenList)
                if (node.childrenTemplate.length !== node.children.length)
                    return true;
        }
        return false;
    };
    function traverse(node) {
        if (permition(node)) {
            result.push(node.id);
            node.children.forEach(traverse);
        }
        else {
            // Иначе проходимся по всем детям
            node.children.forEach(traverse);
        }
    }
    traverse(tree);
    return result;
};
