var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { useDocumentTitle } from '../../../utils/routes/routes';
import { setBreadCrumbs } from '../../../redux/actions/appActions';
import { Groups } from './components/groups/Groups';
import { Users } from './components/users/Users';
import { Audio } from './components/audio/Audio';
import { CSV } from './components/export/CSV';
import { useNavigate } from 'react-router-dom';
import { translater } from '../../../utils/localization/localization';
export var AWS = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    var navigate = useNavigate();
    var _b = __read(useState(0), 2), activeIndex = _b[0], setActiveIndex = _b[1];
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/settings' }]);
    }, []);
    var bloks = [
        {
            header: translater('awsMenuGroupsItem'),
            element: _jsx(Groups, { setActiveIndex: setActiveIndex })
        },
        {
            header: translater('awsMenuUsersItem'),
            element: _jsx(Users, { setActiveIndex: setActiveIndex })
        },
        {
            header: translater('awsMenuAudioItem'),
            element: _jsx(Audio, { setActiveIndex: setActiveIndex })
        },
        {
            header: translater('awsMenuCSVItem'),
            element: _jsx(CSV, {})
        },
    ];
    var handleClearQuery = function () {
        navigate(window.location.pathname, { replace: true });
    };
    var changeTab = function (tabIndex) {
        handleClearQuery();
        setActiveIndex(tabIndex);
    };
    useEffect(function () {
        // При загрузке страницы проверяем параметр user в URL
        var params = new URLSearchParams(location.search);
        var userParam = params.get('user');
        var groupParam = params.get('group');
        if (userParam) {
            setActiveIndex(1);
        }
        if (groupParam) {
            setActiveIndex(0);
        }
    }, [location.search, title]);
    return (_jsx("main", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round m-3" }, { children: _jsx(TabView, __assign({ className: 'aws-tabs', activeIndex: activeIndex, onTabChange: function (e) { return changeTab(e.index); } }, { children: bloks.map(function (_a) {
                var header = _a.header, element = _a.element;
                return (_jsx(TabPanel, __assign({ header: header }, { children: element })));
            }) })) })));
};
