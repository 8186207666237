var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ActiveDirectory } from "../../active-directory/ActiveDirectory";
import { CDR } from "../../cdr/CDR";
import { Information } from "../../information/Information";
import { ResetArea } from "../../reset-area/ResetArea";
import { UpdateComanyName } from "../../update/company-name/UpdateComanyName";
import { DatabaseBackup } from "../../update/database-backup/DatabaseBackup";
import { UpdateEncryptionKey } from "../../update/encryption-key/UpdateEncryptionKey";
export var MenuItems = function (_a) {
    var item = _a.item, settings = _a.settings, breadcrumbs = _a.breadcrumbs, setSettings = _a.setSettings;
    switch (item.type) {
        case 'company-name':
            return _jsx(UpdateComanyName, { settings: settings });
        case 'active-directory':
            return _jsx(ActiveDirectory, { setSettings: setSettings, settings: settings });
        case 'encryption-key':
            return _jsx(UpdateEncryptionKey, { setSettings: setSettings, settings: settings });
        case 'database':
            return (_jsxs("div", __assign({ className: "flex flex-column gap-2 w-full" }, { children: [_jsx(UpdateEncryptionKey, { setSettings: setSettings, settings: settings }), _jsx(DatabaseBackup, { setSettings: setSettings, settings: settings }), _jsx(ResetArea, { setSettings: setSettings, settings: settings })] })));
        case 'cdr':
            return _jsx(CDR, { setSettings: setSettings, settings: settings });
        // case 'pbx-connection':
        //   return <Connection setSettings={setSettings} settings={settings} />
        default:
            return _jsx(Information, { settings: settings });
    }
};
