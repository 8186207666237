var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { hideForm, setFormData, setFormLoaderMode, updateTree } from "../../../../../../redux/actions/builderAction";
import { FormGenerator } from "../../../../../components/particals/form-generator/FormGenerator";
import { findTreeElementById, replaceElementById } from "../../../utils/tree";
import { translater } from "../../../../../../utils/localization/localization";
import { API } from "../../../../../../api";
import { Skeleton } from "primereact/skeleton";
export var Form = function () {
    var _a = useSelector(function (state) { return state.builder; }), formVisible = _a.formVisible, formData = _a.formData, formLoader = _a.formLoader, currentTree = _a.tree;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(false), 2), serverForm = _c[0], setServerForm = _c[1];
    var sendData = function (_a) {
        var data = _a.data;
        delete data['undefined'];
        var id = formData.nodeId;
        var node = findTreeElementById(currentTree, id);
        node.settings = data;
        // Обновляем настройки блока в дереве
        var tree = replaceElementById(currentTree, id, node);
        updateTree(tree);
        hideForm();
        setFormLoaderMode(false);
        setFormData(null);
    };
    var defaultFormValues = formData === null || formData === void 0 ? void 0 : formData.settings;
    var loadElement = function () {
        if (formData) {
            var item = findTreeElementById(currentTree, formData.nodeId);
            if (item && item.formName) {
                setLoading(true);
                API.getForm(item.formName)
                    .then(function (response) {
                    setServerForm(response.data);
                    setLoading(false);
                })
                    .finally(function () { return setLoading(false); });
            }
        }
    };
    useEffect(function () {
        if (formVisible) {
            loadElement();
        }
    }, [formVisible]);
    return (_jsx("div", __assign({ className: "card" }, { children: _jsx(Dialog, __assign({ visible: formVisible, modal: true, resizable: false, headerClassName: "surface-50", contentClassName: "surface-50", contentStyle: { minWidth: '550px' }, onHide: function () { return hideForm(); } }, { children: formLoader || loading
                ? (_jsxs("div", __assign({ className: "flex flex-column gap-3" }, { children: [_jsx(Skeleton, { width: "100%", height: "45px" }), _jsx(Skeleton, { width: "100%", height: "45px" }), _jsx(Skeleton, { width: "100%", height: "45px" }), _jsx(Skeleton, { width: "100%", height: "45px" }), _jsx(Skeleton, { width: "100%", height: "45px" })] }))) : (_jsx("div", __assign({ className: "surface-card mt-2 p-4 shadow-2 border-round w-full" }, { children: formData
                    ? (_jsx(FormGenerator, { defaultFormValues: defaultFormValues, onSend: sendData, json: serverForm ? JSON.stringify(serverForm) : JSON.stringify(formData) }))
                    : (_jsx("span", __assign({ className: 'block text-center' }, { children: translater("formDialogNoData") }))) }))) })) })));
};
