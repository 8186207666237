var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { MultiSelect } from 'primereact/multiselect';
import { useController } from 'react-hook-form';
import { useEffect, useState } from "react";
import { API } from "../../../../../../../../../api";
import { translater } from '../../../../../../../../../utils/localization/localization';
import { Skeleton } from 'primereact/skeleton';
export var UsersField = function (_a) {
    var _b = _a.form, control = _b.control, getValues = _b.getValues;
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState([]), 2), users = _d[0], setUsers = _d[1];
    var _e = __read(useState(undefined), 2), timeout = _e[0], setTimeOut = _e[1];
    var getUsers = function (query) {
        setLoading(true);
        var id = getValues('_id');
        return new Promise(function (resolve) {
            API.groupUsersFinder(id, query)
                .then(function (response) {
                var users = response.data.users;
                resolve(users);
                setLoading(false);
            })
                .catch(function () { return setLoading(false); });
        });
    };
    var usersFinder = function (query) { return __awaiter(void 0, void 0, void 0, function () {
        var users;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getUsers(query)];
                case 1:
                    users = _a.sent();
                    setUsers(users);
                    return [2 /*return*/];
            }
        });
    }); };
    var usersInit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var users, supervisors, candidates;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getUsers('')];
                case 1:
                    users = _a.sent();
                    setUsers(users);
                    supervisors = getValues('supervisors');
                    candidates = users
                        .filter(function (user) {
                        return user.isGroupBelonging && supervisors.find(function (supervisor) { return supervisor._id !== user._id; });
                    });
                    field.onChange(candidates);
                    return [2 /*return*/];
            }
        });
    }); };
    var searchUsers = function (event) {
        clearTimeout(timeout);
        var newTimeout = setTimeout(function () { return usersFinder(event.filter); }, 600);
        setTimeOut(newTimeout);
    };
    useEffect(function () { usersInit(); }, []);
    var field = useController({ control: control, name: "users" }).field;
    var onChangeHandler = function (users) { return field.onChange(users); };
    return (_jsxs(_Fragment, { children: [_jsx("label", __assign({ htmlFor: "users", className: "block text-900 font-medium mb-2" }, { children: translater("groupsPageUsersField") })), loading
                ? _jsx(Skeleton, { width: "100%", height: "45px" })
                : (_jsx(MultiSelect, { onChange: function (event) { return onChangeHandler(event.value); }, value: field.value, options: users, optionLabel: "login", placeholder: translater("groupsPageUsersFieldPlaceholder").toString(), filter: true, onFilter: searchUsers, className: "w-full mb-3" }))] }));
};
