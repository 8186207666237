var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Button } from "primereact/button";
import { translater } from "../../../../../utils/localization/localization";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
export var CSV = function () {
    var _a = __read(useState(false), 2), modalVisible = _a[0], setModalVisible = _a[1];
    var cdr = [
        {
            start: new Date('12.12.2024 15:30'),
            scr: '777',
            dst: '444',
            disposition: 'ANSWER',
            duration: '00:40',
            billsec: '00:30',
            client: 'Ivan',
        },
        {
            start: new Date('12.13.2024 15:30'),
            scr: '888',
            dst: '444',
            disposition: 'BUSY',
            duration: '00:40',
            billsec: '00:30',
            client: 'Mary',
        },
        {
            start: new Date('12.22.2024 15:30'),
            scr: '555',
            dst: '444',
            disposition: 'ANSWER',
            duration: '00:40',
            billsec: '00:30',
            client: 'Alex',
        },
        {
            start: new Date('12.13.2024 15:30'),
            scr: '777',
            dst: '444',
            disposition: 'ANSWER',
            duration: '00:40',
            billsec: '00:30',
            client: 'Petr',
        }
    ];
    var downloadCSV = function () {
        var csvData = cdr
            .map(function (cdrItem) {
            cdrItem.start = new Date(cdrItem.start).toLocaleString().replace(',', '');
            return cdrItem;
        })
            .map(function (cdrItem) {
            return Object.values(cdrItem);
        });
        // Преобразуем данные в CSV строку
        var csvString = csvData.map(function (row) { return row.join(','); }).join('\n');
        // Создаем Blob
        var blob = new Blob([csvString], { type: 'text/csv' });
        // Создаем ссылку и задаем URL для скачивания
        var url = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = 'extensions-example.csv';
        // Клик по ссылке для скачивания
        document.body.appendChild(link);
        link.click();
        // Удаляем ссылку после скачивания
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "flex gap-2 align-items-start" }, { children: [_jsx(Button, { icon: "pi pi-chart-bar", onClick: function () { return setModalVisible(true); }, label: translater("awsCSVButtonLabel").toString(), className: 'p-button-outlined p-button-secondary' }), _jsx(Button, { icon: "pi pi-download", onClick: function () { return downloadCSV(); }, label: translater("awsCSVButtonDownloadCSVLabel").toString(), className: 'p-button-outlined p-button-secondary' })] })), _jsx(Dialog, __assign({ visible: modalVisible, header: translater("awsCSVModalHeaderLabel"), style: { width: '70vw', height: '100%' }, onHide: function () { return setModalVisible(false); } }, { children: _jsx("div", __assign({ className: "w-full surface-card shadow-2 border-round" }, { children: _jsxs(DataTable, __assign({ value: cdr, className: "w-full" }, { children: [_jsx(Column, { sortable: true, field: "start", body: function (row) {
                                    return new Date(row.start).toLocaleString();
                                }, header: "Start" }), _jsx(Column, { field: "scr", header: "Source" }), _jsx(Column, { field: "dst", header: "Destination" }), _jsx(Column, { field: "disposition", header: "Disposition" }), _jsx(Column, { field: "duration", header: "Duration" }), _jsx(Column, { field: "billsec", header: "Conversation" }), _jsx(Column, { field: "client", header: "Client name" })] })) })) }))] }));
};
