var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Editor } from "@monaco-editor/react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { translater } from "../../../../../utils/localization/localization";
// Функиця для преобразования объекта свойств в строку
export var preparedExpertOptions = function (options, isActive) {
    if (isActive === void 0) { isActive = true; }
    return Object
        .entries(options)
        .map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        return "".concat(isActive ? '' : ';').concat(key, "=").concat(value);
    })
        .join('\n');
};
export var uniteExpertOptionsWithMainOptions = function (expert, main, tabs) {
    var fields = tabs
        .filter(function (tab) { return !tab.expertMode; })
        .reduce(function (accum, tab) {
        return accum = __spreadArray(__spreadArray([], __read(accum), false), __read(tab.fields), false);
    }, []);
    var fieldsNames = fields.map(function (field) { return field.field; });
    var mainOptions = Object
        .entries(main)
        .filter(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        // Показываем дополнительно те поля, которые пропали из формы создания
        // и не существуют в expert
        if (fieldsNames.includes(key) === false && expert.includes(key) === false)
            return true;
        return false;
    })
        .map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        var commentSign = '';
        // Если массив
        if (value && Array.isArray(value)) {
            var field = fields.find(function (field) { return field.field === key; });
            var delimiter = field ? field.delimiter : ',';
            return "".concat(commentSign).concat(key, "=").concat(value.map(function (item) {
                return item.keyword;
            }).join(delimiter));
        }
        // Если объект
        if (value && typeof value === 'object') {
            return "".concat(commentSign).concat(key, "=").concat(value.keyword);
        }
        return "".concat(commentSign).concat(key, "=").concat(value);
    })
        .join('\n');
    if (expert.trim() === '')
        return mainOptions;
    if (mainOptions.trim() === '')
        return expert;
    if (mainOptions.trim() === '' && expert.trim() === '')
        return '';
    return [expert, mainOptions].join('\n');
};
export var ExpertTab = function (_a) {
    var tabs = _a.tabs, onExpertCode = _a.onExpertCode, expertCode = _a.expertCode, getValues = _a.form.getValues, readOnly = _a.readOnly, _b = _a.isActive, isActive = _b === void 0 ? true : _b, _c = _a.commentedFields, commentedFields = _c === void 0 ? [] : _c;
    var isDarkMode = useSelector(function (state) { return state.theme; }).isDarkMode;
    var onChangeHandler = function (value) {
        onExpertCode(value || '');
    };
    var editorOptions = {
        folding: true,
        foldingHighlight: true,
        foldingStrategy: 'auto',
        minimap: { enabled: false },
        padding: {
            top: 0,
            bottom: 0,
        },
        // Может понадобиться установить следующее, чтобы убрать некоторые другие отступы
        lineDecorationsWidth: 0,
        lineNumbersMinChars: 0,
        glyphMargin: false,
        scrollbar: {
            // Можно скрыть полосы прокрутки, если они не нужны
            vertical: 'hidden',
            horizontal: 'hidden'
        },
        overviewRulerLanes: 0
    };
    // Поля для получения разделителя
    var fields = tabs
        .filter(function (tab) { return !tab.expertMode; })
        .reduce(function (accum, tab) {
        return accum = __spreadArray(__spreadArray([], __read(accum), false), __read(tab.fields), false);
    }, []);
    // Данные для вывода в левой части
    var preparedDefaultValues = Object
        .entries(getValues())
        // Пропускаем вывод поля именени (выводим в span выше редактора)
        .filter(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        // Скрываем поле name и показываем только те поля, которые есть в форме 
        // (которая с сервера прилетает – всегда новая)
        return key !== 'name' && fields.find(function (field) { return field.field === key.replace(';', ''); });
    })
        .map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        var commentSign = commentedFields.includes(key) ? ';' : '';
        // Если массив
        if (value && Array.isArray(value)) {
            var field = fields.find(function (field) { return field.field === key; });
            var delimiter = field ? field.delimiter : ',';
            return "".concat(commentSign).concat(key, "=").concat(value.map(function (item) {
                return item.keyword;
            }).join(delimiter));
        }
        // Если объект
        if (value && typeof value === 'object') {
            return "".concat(commentSign).concat(key, "=").concat(value.keyword);
        }
        return "".concat(commentSign).concat(key, "=").concat(value);
    })
        .join('\n');
    // Имена табов, которые включают в себя поля
    var otherTabNames = tabs
        .filter(function (tab) { return !tab.expertMode; })
        .map(function (tab) { return translater(tab.titleTranslaterKey); })
        .join(' + ');
    return (_jsxs("div", __assign({ className: "w-full flex gap-2" }, { children: [_jsxs("div", __assign({ className: "w-full flex flex-column gap-2" }, { children: [_jsx("h4", { children: otherTabNames }), _jsxs("span", __assign({ className: clsx('text-sm ml-4', {
                            'text-green-700': !isActive
                        }) }, { children: [!isActive ? '; ' : '', "[", getValues('name'), "]"] })), _jsx(Editor, { value: preparedDefaultValues, options: __assign(__assign({}, editorOptions), { readOnly: true }), className: "p-0 w-full", height: "30vh", theme: isDarkMode ? 'hc-black' : 'vs', language: "ini" })] })), _jsxs("div", __assign({ className: "w-full flex flex-column gap-2" }, { children: [_jsx("h4", { children: translater("expertTabExpertMode") }), _jsxs("span", __assign({ className: clsx('text-sm ml-4', {
                            'text-green-700': !isActive
                        }) }, { children: [!isActive ? '; ' : '', "[", getValues('name'), "](+)"] })), _jsx(Editor, { value: expertCode, options: __assign(__assign({}, editorOptions), { readOnly: readOnly }), className: "p-0 w-full", height: "30vh", theme: isDarkMode ? 'hc-black' : 'vs', onChange: onChangeHandler, language: "ini" })] }))] })));
};
