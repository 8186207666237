var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from "axios";
export var getWebsiteUrl = function () {
    var website = "".concat(location.origin);
    var domain = 'localhost';
    // const domain = 'docker.dmmp.me'
    if (website.includes('localhost'))
        return { website: "http://".concat(domain), domain: domain };
    return { website: website, domain: location.host };
};
var urlPath = getWebsiteUrl().website;
var path = function (path) { return "".concat(urlPath, "/api") + path; };
// INITIALIZATION
var adminInitialization = function (data) {
    return axios.post(path('/initialization/admin-initialization'), data);
};
var initializationProccess = function () {
    return axios.get(path('/initialization/initialization-proccess'));
};
var serverConnection = function (data, cancelToken) {
    var _a;
    var config = {
        method: 'post',
        url: path('/initialization/server-connection'),
        data: data,
        // Отмена запроса
        cancelToken: (_a = cancelToken.current) === null || _a === void 0 ? void 0 : _a.token
    };
    return axios(config);
};
var testCDRDatabaseConnection = function (data, cancelToken) {
    var _a;
    var config = {
        method: 'post',
        url: path('/initialization/cdr-database'),
        data: data,
        // Отмена запроса
        cancelToken: (_a = cancelToken.current) === null || _a === void 0 ? void 0 : _a.token
    };
    return axios(config);
};
var checkActiveDirectoryConnection = function (data, cancelToken) {
    var _a;
    var config = {
        method: 'post',
        url: path('/initialization/check-active-directory-connection'),
        data: data,
        // Отмена запроса
        cancelToken: (_a = cancelToken.current) === null || _a === void 0 ? void 0 : _a.token
    };
    return axios(config);
};
var saveActiveDirectory = function (data, cancelToken) {
    var _a;
    var config = {
        method: 'post',
        url: path('/initialization/save-active-directory'),
        data: data,
        // Отмена запроса
        cancelToken: (_a = cancelToken.current) === null || _a === void 0 ? void 0 : _a.token
    };
    return axios(config);
};
var initCertificate = function (data) {
    return axios.post(path('/initialization/save-certificate'), data);
};
// AUTH
var login = function (data) {
    return axios.post(path('/auth/login'), data);
};
var activeDirectoryLogin = function (data) {
    return axios.post(path('/auth/active-directory-login'), data);
};
var getUserRoleToken = function (data) {
    return axios.post(path('/auth/get-user-role-token'), data);
};
// CALLS
var getCalls = function (phones, filters) {
    return axios.post(path('/calls'), { phones: phones, filters: filters });
};
var checkMissingCallsExistence = function (phones) {
    return axios.post(path('/calls/check-missing-existence'), { phones: phones });
};
var getMissingCallsPeriod = function () {
    return axios.get(path('/calls/get-missing-calls-period'));
};
var changeCallType = function (id) {
    return axios.post(path('/calls/change-call-type'), { id: id });
};
// CERTIFICATES
var getMeta = function (data) {
    return axios.post(path('/certificates/get-meta'), data);
};
var getCertificates = function () {
    return axios.get(path('/certificates'));
};
var createCertificate = function (data) {
    return axios.post(path('/certificates/create'), data);
};
var removeCertificate = function (id) {
    return axios.delete(path('/certificates/remove/' + id));
};
// GROUPS
var groupsFinder = function (query, filters) {
    return axios.post(path('/groups/finder'), { query: query, filters: filters });
};
var createGroup = function (data) {
    return axios.post(path('/groups/create'), data);
};
var updateGroup = function (data) {
    return axios.put(path('/groups/update'), data);
};
var removeGroups = function (ids) {
    return axios.post(path('/groups/remove-many'), { ids: ids });
};
var getGroupUsers = function (id, query, filters) {
    if (query === void 0) { query = ''; }
    if (filters === void 0) { filters = { first: 0 }; }
    return axios.post(path('/groups/get-users'), { id: id, query: query, filters: filters });
};
var groupSupervisorsFinder = function (id, query, filters) {
    if (query === void 0) { query = ''; }
    if (filters === void 0) { filters = { first: 0 }; }
    return axios.post(path('/groups/supervisors-finder'), { id: id, query: query, filters: filters });
};
var groupUsersFinder = function (id, query, filters) {
    if (query === void 0) { query = ''; }
    if (filters === void 0) { filters = { first: 0 }; }
    return axios.post(path('/groups/users-finder'), { id: id, query: query, filters: filters });
};
var addGroupsUsers = function (ids, users) {
    return axios.post(path('/groups/add-groups-users'), { ids: ids, users: users });
};
var removeGroupsUsers = function (ids, users) {
    return axios.post(path('/groups/remove-groups-users'), { ids: ids, users: users });
};
// USERS
var usersFinder = function (query, filters) {
    if (query === void 0) { query = ''; }
    return axios.post(path('/users/finder'), { query: query, filters: filters });
};
var supervisorsFinder = function (query, filters) {
    if (query === void 0) { query = ''; }
    if (filters === void 0) { filters = { first: 0 }; }
    return axios.post(path('/users/supervisors-finder'), { query: query, filters: filters });
};
var usersActiveDirectoryImport = function (ids) {
    return axios.post(path('/users/upload-active-directory'), { ids: ids });
};
var getUploadedActiveDirectoryUsers = function () {
    return axios.get(path('/users/get-uploaded-active-directory-users'));
};
var createUser = function (data) {
    return axios.post(path('/users/create'), data);
};
var updateUser = function (data) {
    return axios.put(path('/users/update'), data);
};
var removeUsers = function (ids) {
    return axios.post(path('/users/remove-many'), { ids: ids });
};
var getUserRoles = function (id) {
    return axios.get(path('/users/get-user-roles/' + id));
};
// ROLES
var getRoles = function () { return axios.get(path('/roles')); };
// REDIRECTS
var getRedirects = function () {
    return axios.get(path('/redirects'));
};
var createRedirect = function (data) {
    return axios.post(path('/redirects/create'), data);
};
var updateRedirect = function (data) {
    return axios.put(path('/redirects/update'), data);
};
var removeRedirect = function (id) {
    return axios.delete(path('/redirects/remove/' + id));
};
// FORM GENERATOR
var saveAudio = function (data) {
    return axios.post(path('/builder/save-audio'), data);
};
var getBlocksNames = function (blockType) {
    return axios.get(path('/builder/get-blocks-names/' + blockType));
};
var getBlock = function (name, id) {
    return axios.post(path('/builder/get-block'), { name: name, id: id });
};
// BUILDER
var getBlocks = function () {
    return axios.get(path('/builder/blocks/get-all'));
};
var getForm = function (key) {
    return axios.get(path('/builder/forms/get/' + key));
};
// LISTS
var listsFinder = function (query, filters) {
    return axios.post(path('/builder/lists/finder'), { query: query, filters: filters });
};
var createList = function (data) {
    return axios.post(path('/builder/lists/create'), data);
};
var prepareDialplan = function (data) {
    return axios.post(path('/builder/prepare-dialplan'), data);
};
var updateList = function (data) {
    return axios.put(path('/builder/lists/update'), data);
};
var removeList = function (id) {
    return axios.delete(path('/builder/lists/remove/' + id));
};
// RULES
var rulesFinder = function (query, filters) {
    return axios.post(path('/rules/finder'), { query: query, filters: filters });
};
var createRule = function (data) {
    return axios.post(path('/rules/create'), data);
};
var updateRule = function (data) {
    return axios.put(path('/rules/update'), data);
};
var getRuleById = function (id) {
    return axios.get(path('/rules/get/' + id));
};
var removeRules = function (ids) {
    return axios.post(path('/rules/remove-many'), { ids: ids });
};
var checkRulesBeforeRemoving = function (ids) {
    return axios.post(path('/rules/check-before-removing'), { ids: ids });
};
// CONTEXTS
var contextsFinder = function (query, filters) {
    return axios.post(path('/contexts/finder'), { query: query, filters: filters });
};
var createContext = function (data) {
    return axios.post(path('/contexts/create'), __assign(__assign({}, data), { location: 'db' }));
};
var updateContext = function (data) {
    return axios.put(path('/contexts/update'), __assign(__assign({}, data), { location: 'db' }));
};
var getContextById = function (id) {
    return axios.get(path('/contexts/get/' + id));
};
var removeContexts = function (ids) {
    return axios.post(path('/contexts/remove-many'), { ids: ids });
};
var checkContextsBeforeRemoving = function (ids) {
    return axios.post(path('/contexts/check-before-removing'), { ids: ids });
};
// STATIONS
var getStationsTemplate = function () {
    return axios.get(path('/stations/template'));
};
var createStation = function (data) {
    return axios.post(path('/stations/create'), data);
};
var checkStationExistence = function (data) {
    return axios.post(path('/stations/check-station-existence'), data);
};
var checkStationAccess = function (data) {
    return axios.post(path('/stations/check-station-access'), data);
};
var stationsFinder = function (query, filters) {
    return axios.post(path('/stations/finder'), { query: query, filters: filters });
};
var sshCopyId = function (data) {
    return axios.post(path('/stations/ssh-copy-id'), data);
};
var removeStations = function (ids) {
    return axios.post(path('/stations/remove-many'), { ids: ids });
};
var checkStationsBeforeRemoving = function (ids) {
    return axios.post(path('/stations/check-before-removing'), { ids: ids });
};
// FILES
var createFile = function (data) {
    return axios.post(path('/files/create'), data);
};
var updateFile = function (data) {
    return axios.put(path('/files/update'), data);
};
var getDefaultFileData = function (data) {
    return axios.post(path('/files/get-default-file-data'), data);
};
var getCurrentFileData = function (name, stationName) {
    return axios.post(path('/files/get-file-content'), { name: name, pbx: stationName });
};
var prepareFilesHistory = function (names) {
    return axios.post(path('/files/prepare-files-history'), { names: names });
};
var getFileFolders = function () {
    return axios.get(path('/files/get-file-folders'));
};
var filesFinder = function (query, filters) {
    return axios.post(path('/files/finder'), { query: query, filters: filters });
};
var removeFiles = function (ids) {
    return axios.post(path('/files/remove-many'), { ids: ids });
};
// TRUNKS
var trunksFinder = function (query, filters) {
    return axios.post(path('/trunks/finder'), { query: query, filters: filters });
};
var createTrunk = function (data) {
    return axios.post(path('/trunks/create'), data);
};
var updateTrunk = function (data) {
    return axios.put(path('/trunks/update'), data);
};
var getTrunkById = function (id) {
    return axios.get(path('/trunks/get/' + id));
};
var getTrunkTypes = function () {
    return axios.get(path('/trunks/get-types'));
};
var getTypeTemplate = function (type) {
    return axios.get(path('/trunks/get-type-template/' + type));
};
var getTrunksColumnNames = function () {
    return axios.get(path('/trunks/get-table-column-names'));
};
var removeTrunks = function (ids) {
    return axios.post(path('/trunks/remove-many'), { ids: ids });
};
var setTrunksActiveMode = function (ids, mode) {
    return axios.post(path('/trunks/set-active-mode'), { ids: ids, mode: mode });
};
var checkTrunksBeforeRemoving = function (ids) {
    return axios.post(path('/trunks/check-before-removing'), { ids: ids });
};
// TEMPLATES
var templatesFinder = function (query, filters) {
    return axios.post(path('/templates/finder'), { query: query, filters: filters });
};
var createTemplate = function (data) {
    return axios.post(path('/templates/create'), data);
};
var updateTemplate = function (data) {
    return axios.put(path('/templates/update'), data);
};
var removeTemplates = function (ids) {
    return axios.post(path('/templates/remove-many'), { ids: ids });
};
var checkTemplatesBeforeRemoving = function (ids) {
    return axios.post(path('/templates/check-before-removing'), { ids: ids });
};
var getTemplateCofig = function () {
    return axios.get(path('/templates/get-template/'));
};
var getTemplatesColumnNames = function () {
    return axios.get(path('/templates/get-table-column-names'));
};
var getTemplateById = function (id) {
    return axios.get(path('/templates/${id}'));
};
// EXTENSIONS
var extensionsFinder = function (query, filters) {
    return axios.post(path('/extensions/finder'), { query: query, filters: filters });
};
var createExtension = function (data) {
    return axios.post(path('/extensions/create'), data);
};
var updateExtension = function (data) {
    return axios.put(path('/extensions/update'), data);
};
var removeExtensions = function (ids) {
    return axios.post(path('/extensions/remove-many'), { ids: ids });
};
var getExtensionConfig = function () {
    return axios.get(path('/extensions/get-template/'));
};
var getExtensionsColumnNames = function () {
    return axios.get(path('/extensions/get-table-column-names'));
};
var checkExtensionsBeforeRemoving = function (ids) {
    return axios.post(path('/extensions/check-before-removing'), { ids: ids });
};
var importCSVData = function (data) {
    return axios.post(path('/extensions/import-csv-data'), { data: data });
};
// CONTEXT
var getChildrenByContext = function (id) {
    return axios.get(path('/contexts/get-context-children/' + id));
};
var getAllChildren = function (ignoreContext) {
    return axios.post(path('/contexts/get/children'), { ignoreContext: ignoreContext });
};
var contextsSaveOptions = function (context, children) {
    return axios.post(path('/contexts/save/children'), { context: context, children: children });
};
var removeChildren = function (context, ids) {
    return axios.post(path('/contexts/remove/children'), { context: context, ids: ids });
};
// CONTENT
var getLanguages = function () {
    return axios.get(path('/content/languages'));
};
var getCompanyName = function () {
    return axios.get(path('/content/company-name'));
};
var updateCompanyName = function (company) {
    return axios.post(path('/content/update-company-name'), { name: company });
};
var resaveBackupProcess = function (interval, folderPath, time) {
    return axios.post(path('/content/resave-backup-process'), { interval: interval, path: folderPath, time: time });
};
var updateClientUrl = function (url) {
    return axios.post(path('/content/update-client-url'), { url: url });
};
var getServerData = function (apiPath) {
    return axios.get(path(apiPath));
};
var databaseExport = function (key) {
    return axios.post(path('/content/database-export'), { key: key || '' }, {
        responseType: 'blob'
    });
};
var getSettingsData = function () {
    return axios.get(path('/content/get-app-settings'));
};
var databaseImport = function (formData) {
    return axios.post(path('/content/database-import'), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};
var checkEncryptionKeyExistence = function () {
    return axios.post(path('/content/check-encryption-key-existence'));
};
var createEncryptionKey = function (key) {
    return axios.post(path('/content/create-encryption-key'), { key: key });
};
var updateEncryptionKey = function (key) {
    return axios.post(path('/content/update-encryption-key'), { key: key });
};
// EDITOR
var getConfigurationFileNames = function (folderPath) {
    return axios.post(path('/editor/configuration-files'), { folderPath: folderPath });
};
var createFolderThroughEditor = function (folderPath) {
    return axios.post(path('/editor/create-folder'), { folderPath: folderPath });
};
var getConfigurationFile = function (fileName) {
    return axios.post(path('/editor/conf-file-content/'), { name: fileName });
};
var updateConfigurationFileContent = function (fileName, content) {
    return axios.put(path('/editor/update'), { name: fileName, content: content });
};
var createConfigurationFileContent = function (fileName, content) {
    return axios.post(path('/editor/create'), { name: fileName, content: content });
};
var removeCofigurationFile = function (fileName) {
    return axios.post(path('/editor/remove/'), { name: fileName });
};
// QUEUES
var getQueuesTemplate = function () {
    return axios.get(path('/queues/template'));
};
var createQueue = function (data) {
    return axios.post(path('/queues/create'), data);
};
var queuesFinder = function (query, filters) {
    return axios.post(path('/queues/finder'), { query: query, filters: filters });
};
var addQueueUser = function (userId, queueId, penalty) {
    return axios.post(path('/queues/add-user'), { userId: userId, queueId: queueId, penalty: penalty });
};
var updateQueueUserPenalty = function (userId, queueId, penalty) {
    return axios.post(path('/queues/update-penalty'), { userId: userId, queueId: queueId, penalty: penalty });
};
var removeQueueUser = function (userId, queueId) {
    return axios.post(path('/queues/remove-user'), { userId: userId, queueId: queueId });
};
var queueUsersFinder = function (query, filters) {
    return axios.post(path('/queues/user-finder'), { query: query, filters: filters });
};
// SYSTEM
var applySettings = function () {
    return axios.post(path('/system/apply-settings'), {});
};
var databaseVerification = function () {
    return axios.post(path('/system/database-verification'), {});
};
var createMongoDatabase = function () {
    return axios.post(path('/system/create-database'), {});
};
var dropMongoDatabase = function () {
    return axios.post(path('/system/drop-database'), {});
};
var checkApplicatoinUpdates = function () {
    return axios.post(path('/system/check-updates'), {});
};
var applyRemoteApplicationUpdates = function () {
    return axios.post(path('/system/apply-remote-updates'), {});
};
// INTERPRISE SUPPORT
var uploadVPNFile = function (formData) {
    return axios.post(path('/content/vpn-file-upload'), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};
// AWS
var createAWSUser = function (data) {
    return axios.post(path('/aws/create-user'), data);
};
var updateAWSUser = function (data) {
    return axios.post(path('/aws/update-user'), data);
};
var finderAWSUser = function (query, filters) {
    return axios.post(path('/aws/user-finder'), { query: query, filters: filters });
};
var removeAWSUser = function (ids) {
    return axios.post(path('/aws/users-remove'), { ids: ids });
};
var createAWSAudio = function (data) {
    return axios.post(path('/aws/create-audio'), data);
};
var updateAWSAudio = function (data) {
    return axios.post(path('/aws/update-audio'), data);
};
var removeAWSAudio = function (ids) {
    return axios.post(path('/aws/audio-remove'), { ids: ids });
};
var finderAWSAudio = function (query, filters) {
    return axios.post(path('/aws/audio-finder'), { query: query, filters: filters });
};
var createAWSGroup = function (data) {
    return axios.post(path('/aws/create-group'), data);
};
var updateAWSGroup = function (data) {
    return axios.post(path('/aws/update-group'), data);
};
var removeAWSGroup = function (ids) {
    return axios.post(path('/aws/group-remove'), { ids: ids });
};
var finderAWSGroup = function (query, filters) {
    return axios.post(path('/aws/group-finder'), { query: query, filters: filters });
};
var checkAudioBeforeRemoving = function (ids) {
    return axios.post(path('/aws/check-audio-before-removing'), { ids: ids });
};
var checkGroupsBeforeRemoving = function (ids) {
    return axios.post(path('/aws/check-groups-before-removing'), { ids: ids });
};
var vpnFileExistence = function () {
    return axios.post(path('/content/vpn-file-existence'), {});
};
// OTHER
var getPhones = function () {
    return axios.get(path('/users/get-phones'));
};
var getPhonesStatistics = function (phones, period) {
    return axios.post(path('/calls/phones-statistics'), { phones: phones, period: period });
};
export var API = {
    vpnFileExistence: vpnFileExistence,
    uploadVPNFile: uploadVPNFile,
    applySettings: applySettings,
    login: login,
    getUserRoleToken: getUserRoleToken,
    getCalls: getCalls,
    checkMissingCallsExistence: checkMissingCallsExistence,
    getMissingCallsPeriod: getMissingCallsPeriod,
    changeCallType: changeCallType,
    activeDirectoryLogin: activeDirectoryLogin,
    adminInitialization: adminInitialization,
    initializationProccess: initializationProccess,
    getLanguages: getLanguages,
    serverConnection: serverConnection,
    testCDRDatabaseConnection: testCDRDatabaseConnection,
    checkActiveDirectoryConnection: checkActiveDirectoryConnection,
    saveActiveDirectory: saveActiveDirectory,
    getMeta: getMeta,
    getRedirects: getRedirects,
    initCertificate: initCertificate,
    getCertificates: getCertificates,
    createCertificate: createCertificate,
    removeCertificate: removeCertificate,
    usersActiveDirectoryImport: usersActiveDirectoryImport,
    getUploadedActiveDirectoryUsers: getUploadedActiveDirectoryUsers,
    getRoles: getRoles,
    createUser: createUser,
    updateUser: updateUser,
    getUserRoles: getUserRoles,
    removeUsers: removeUsers,
    usersFinder: usersFinder,
    supervisorsFinder: supervisorsFinder,
    getPhones: getPhones,
    createFolderThroughEditor: createFolderThroughEditor,
    groupsFinder: groupsFinder,
    updateGroup: updateGroup,
    removeGroups: removeGroups,
    createGroup: createGroup,
    getGroupUsers: getGroupUsers,
    groupSupervisorsFinder: groupSupervisorsFinder,
    addGroupsUsers: addGroupsUsers,
    removeGroupsUsers: removeGroupsUsers,
    groupUsersFinder: groupUsersFinder,
    createRedirect: createRedirect,
    updateRedirect: updateRedirect,
    removeRedirect: removeRedirect,
    saveAudio: saveAudio,
    getBlocks: getBlocks,
    getForm: getForm,
    listsFinder: listsFinder,
    createList: createList,
    updateList: updateList,
    removeList: removeList,
    getBlocksNames: getBlocksNames,
    getBlock: getBlock,
    rulesFinder: rulesFinder,
    createRule: createRule,
    updateRule: updateRule,
    getRuleById: getRuleById,
    removeRules: removeRules,
    checkRulesBeforeRemoving: checkRulesBeforeRemoving,
    contextsFinder: contextsFinder,
    createContext: createContext,
    updateContext: updateContext,
    getContextById: getContextById,
    removeContexts: removeContexts,
    checkContextsBeforeRemoving: checkContextsBeforeRemoving,
    contextsSaveOptions: contextsSaveOptions,
    getChildrenByContext: getChildrenByContext,
    getAllChildren: getAllChildren,
    removeChildren: removeChildren,
    setTrunksActiveMode: setTrunksActiveMode,
    getPhonesStatistics: getPhonesStatistics,
    prepareDialplan: prepareDialplan,
    getConfigurationFileNames: getConfigurationFileNames,
    getConfigurationFile: getConfigurationFile,
    updateConfigurationFileContent: updateConfigurationFileContent,
    createConfigurationFileContent: createConfigurationFileContent,
    removeCofigurationFile: removeCofigurationFile,
    trunksFinder: trunksFinder,
    createTrunk: createTrunk,
    updateTrunk: updateTrunk,
    getTrunkById: getTrunkById,
    getTrunkTypes: getTrunkTypes,
    removeTrunks: removeTrunks,
    getTypeTemplate: getTypeTemplate,
    checkTrunksBeforeRemoving: checkTrunksBeforeRemoving,
    getStationsTemplate: getStationsTemplate,
    stationsFinder: stationsFinder,
    removeStations: removeStations,
    sshCopyId: sshCopyId,
    createStation: createStation,
    checkStationExistence: checkStationExistence,
    checkStationAccess: checkStationAccess,
    checkStationsBeforeRemoving: checkStationsBeforeRemoving,
    templatesFinder: templatesFinder,
    createTemplate: createTemplate,
    updateTemplate: updateTemplate,
    removeTemplates: removeTemplates,
    getTemplateCofig: getTemplateCofig,
    getTemplatesColumnNames: getTemplatesColumnNames,
    checkTemplatesBeforeRemoving: checkTemplatesBeforeRemoving,
    getTemplateById: getTemplateById,
    extensionsFinder: extensionsFinder,
    createExtension: createExtension,
    updateExtension: updateExtension,
    removeExtensions: removeExtensions,
    getExtensionConfig: getExtensionConfig,
    getExtensionsColumnNames: getExtensionsColumnNames,
    checkExtensionsBeforeRemoving: checkExtensionsBeforeRemoving,
    importCSVData: importCSVData,
    getServerData: getServerData,
    databaseExport: databaseExport,
    databaseImport: databaseImport,
    checkEncryptionKeyExistence: checkEncryptionKeyExistence,
    createEncryptionKey: createEncryptionKey,
    updateEncryptionKey: updateEncryptionKey,
    getTrunksColumnNames: getTrunksColumnNames,
    getQueuesTemplate: getQueuesTemplate,
    createQueue: createQueue,
    queuesFinder: queuesFinder,
    addQueueUser: addQueueUser,
    updateQueueUserPenalty: updateQueueUserPenalty,
    removeQueueUser: removeQueueUser,
    queueUsersFinder: queueUsersFinder,
    createFile: createFile,
    filesFinder: filesFinder,
    removeFiles: removeFiles,
    updateFile: updateFile,
    getDefaultFileData: getDefaultFileData,
    getCurrentFileData: getCurrentFileData,
    getFileFolders: getFileFolders,
    prepareFilesHistory: prepareFilesHistory,
    databaseVerification: databaseVerification,
    createMongoDatabase: createMongoDatabase,
    dropMongoDatabase: dropMongoDatabase,
    checkApplicatoinUpdates: checkApplicatoinUpdates,
    applyRemoteApplicationUpdates: applyRemoteApplicationUpdates,
    getCompanyName: getCompanyName,
    updateCompanyName: updateCompanyName,
    resaveBackupProcess: resaveBackupProcess,
    updateClientUrl: updateClientUrl,
    getSettingsData: getSettingsData,
    createAWSUser: createAWSUser,
    updateAWSUser: updateAWSUser,
    removeAWSUser: removeAWSUser,
    finderAWSUser: finderAWSUser,
    createAWSAudio: createAWSAudio,
    finderAWSAudio: finderAWSAudio,
    updateAWSAudio: updateAWSAudio,
    removeAWSAudio: removeAWSAudio,
    createAWSGroup: createAWSGroup,
    finderAWSGroup: finderAWSGroup,
    updateAWSGroup: updateAWSGroup,
    removeAWSGroup: removeAWSGroup,
    checkAudioBeforeRemoving: checkAudioBeforeRemoving,
    checkGroupsBeforeRemoving: checkGroupsBeforeRemoving,
};
