var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { getWebsiteUrl } from '../../../../../../../api';
import { translater } from '../../../../../../../utils/localization/localization';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
export var SimplePlayer = function (_a) {
    var audioId = _a.audioId, _b = _a.template, template = _b === void 0 ? 'minimalist' : _b;
    var _c = __read(useState(false), 2), isPlaying = _c[0], setIsPlaying = _c[1];
    var _d = __read(useState(null), 2), audioFile = _d[0], setAudioFile = _d[1]; // Хранение URL аудиофайла
    var audioRef = useRef(null);
    var handlePlayPause = function () { return __awaiter(void 0, void 0, void 0, function () {
        var urlPath, response, blob, audioUrl, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!audioFile) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    urlPath = getWebsiteUrl().website;
                    return [4 /*yield*/, fetch("".concat(urlPath, "/api/aws/audio/").concat(audioId))];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error(translater("awsAudioItemHeaderErrorFailedToFetch").toString());
                    }
                    return [4 /*yield*/, response.blob()];
                case 3:
                    blob = _a.sent();
                    audioUrl = URL.createObjectURL(blob);
                    setAudioFile(audioUrl);
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    console.error(translater("awsAudioItemHeaderErrorLoading").toString(), error_1);
                    return [2 /*return*/];
                case 5:
                    if (audioRef.current) {
                        if (isPlaying) {
                            audioRef.current.pause();
                        }
                        else {
                            audioRef.current.play();
                        }
                        setIsPlaying(!isPlaying);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleAudioEnded = function () {
        setIsPlaying(false); // Сбрасываем состояние проигрывания при завершении записи
    };
    return (_jsx("div", { children: _jsxs("div", { children: [audioFile && _jsx("audio", { ref: audioRef, src: audioFile, onEnded: handleAudioEnded }), template === 'table' ? (_jsxs(_Fragment, { children: [_jsx(Button, { "data-pr-tooltip": translater(isPlaying ? "stopListenAudioBtn" : "listenAudioBtn"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", icon: "pi ".concat(isPlaying ? 'pi-pause' : 'pi-play'), className: "custom-target-icon-listen-item-".concat(audioId, " p-button p-component p-button-icon-only p-button-outlined p-button-secondary border-circle"), style: { width: "50px", height: "50px" }, onClick: handlePlayPause }), _jsx(Tooltip, { target: "custom-target-icon-listen-item-".concat(audioId) })] })) : (''), template === 'minimalist' ? (_jsx("i", { onClick: handlePlayPause, className: classNames('text-600 text-sm cursor-pointer pi', {
                        'pi pi-pause': isPlaying,
                        'pi pi-play': !isPlaying,
                    }) })) : ''] }) }));
};
