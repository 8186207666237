var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from "primereact/skeleton";
export var UpdateRulePreloader = function () {
    return (_jsxs("main", __assign({ className: "max-w-full m-3 flex flex-column gap-2" }, { children: [_jsxs("div", __assign({ className: "flex gap-2 justify-content-between" }, { children: [_jsx("div", __assign({ className: "flex gap-2" }, { children: _jsx(Skeleton, { width: "110px", height: "50px" }) })), _jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(Skeleton, { width: "70px", height: "50px" }), _jsx(Skeleton, { width: "70px", height: "50px" }), _jsx(Skeleton, { width: "70px", height: "50px" })] }))] })), _jsxs("div", __assign({ className: "flex gap-3 mt-2" }, { children: [_jsxs("div", __assign({ className: "flex gap-2 flex-column" }, { children: [_jsx(Skeleton, { width: "170px", height: "45px" }), _jsx(Skeleton, { width: "170px", height: "45px" }), _jsx(Skeleton, { width: "170px", height: "45px" }), _jsx(Skeleton, { width: "170px", height: "45px" }), _jsx(Skeleton, { width: "170px", height: "45px" })] })), _jsx(Skeleton, { width: "calc(100% - 185px)", height: "258px" })] }))] })));
};
