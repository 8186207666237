var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSpring, animated } from '@react-spring/web';
import { useDocumentTitle } from "../../../../utils/routes/routes";
import { Logs } from "./components/logs/Logs";
import { Calls } from "./components/main/Calls";
import { Security } from "./components/main/Security";
import { CPU } from "./components/statistics/CPU";
import { Memory } from "./components/statistics/Memory";
import { Processes } from "./components/statistics/Processes";
import { RAM } from "./components/statistics/RAM";
import { Suggestion } from "./components/suggestion/Suggestion";
export var animationParams = {
    opacity: 1,
    transform: 'translateY(0)',
    from: {
        opacity: 0,
        transform: 'translateY(30px)', // Элемент начнет движение с 30px ниже
    },
    config: { tension: 100, friction: 20 }, // Параметры для плавности
};
export var ExperimentalHome = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    var springProps = useSpring(animationParams);
    return (_jsx(animated.div, __assign({ style: springProps }, { children: _jsxs("main", __assign({ className: "w-full p-3 flex flex-column gap-2" }, { children: [_jsx("div", __assign({ className: "w-full flex align-items-center gap-2" }, { children: _jsx(Suggestion, {}) })), _jsx("div", __assign({ className: "w-full flex align-items-center gap-2" }, { children: _jsxs("div", __assign({ className: "w-full flex align-items-center gap-2" }, { children: [_jsx(CPU, {}), _jsx(RAM, {}), _jsx(Processes, {}), _jsx(Memory, {})] })) })), _jsxs("div", __assign({ className: "w-full flex align-items-center gap-2" }, { children: [_jsx(Calls, {}), _jsx(Security, {})] })), _jsx("div", __assign({ className: "w-full flex align-items-center gap-2" }, { children: _jsx(Logs, {}) }))] })) })));
};
