var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ExpertTab, preparedExpertOptions } from "../../../components/particals/form-generator/components/ExpertTab";
import { getDefaultValues, getStructureCommentedFields } from "../../../components/particals/form-generator/utils/form";
function addPrefixToKeys(obj, prefix) {
    var newObj = {};
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            newObj[prefix + key] = obj[key];
        }
    }
    return newObj;
}
export var PreviewDialog = function (_a) {
    var modifiedTrunk = _a.modifiedTrunk, hideDialog = _a.hideDialog;
    var _b = __read(useState(modifiedTrunk ? preparedExpertOptions(modifiedTrunk.expertConfig, modifiedTrunk.isActive) : ""), 1), expertCode = _b[0];
    var _c = __read(useState(null), 2), modifiedTrunkStructure = _c[0], setForm = _c[1];
    var defaultValues = (modifiedTrunk === null || modifiedTrunk === void 0 ? void 0 : modifiedTrunk.config) || getDefaultValues(modifiedTrunk === null || modifiedTrunk === void 0 ? void 0 : modifiedTrunk.config);
    var trunkName = modifiedTrunk === null || modifiedTrunk === void 0 ? void 0 : modifiedTrunk.name;
    var form = useForm({
        mode: "all",
        defaultValues: !(modifiedTrunk === null || modifiedTrunk === void 0 ? void 0 : modifiedTrunk.isActive) ? __assign(__assign({}, addPrefixToKeys(defaultValues, ';')), { name: trunkName }) : defaultValues,
    });
    var commentedFields = getStructureCommentedFields(modifiedTrunk === null || modifiedTrunk === void 0 ? void 0 : modifiedTrunk.structure);
    useEffect(function () {
        // Открываем элемент
        if (modifiedTrunk) {
            var form_1 = JSON.parse(modifiedTrunk.structure);
            setForm(form_1);
        }
    }, [modifiedTrunk]);
    return (_jsx("div", __assign({ className: "card" }, { children: _jsx(Dialog, __assign({ visible: true, modal: true, draggable: false, resizable: false, headerClassName: "surface-50", contentClassName: "surface-50", className: "form-dialog-window relative", contentStyle: { minHeight: "400px" }, style: { minWidth: "800px" }, onHide: function () { return hideDialog(); } }, { children: _jsx(ExpertTab, { expertCode: expertCode, onExpertCode: function () { }, form: form, readOnly: true, commentedFields: commentedFields, isActive: modifiedTrunk === null || modifiedTrunk === void 0 ? void 0 : modifiedTrunk.isActive, tabs: modifiedTrunkStructure ? modifiedTrunkStructure.tabs : [] }) })) })));
};
