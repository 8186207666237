var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from 'primereact/dialog';
import { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { API } from '../../../../../../../../api';
import { successNotification } from '../../../../../../../../redux/actions/notificationsActions';
import { translater } from '../../../../../../../../utils/localization/localization';
import { OverlayPanel } from 'primereact/overlaypanel';
export var UpdateUserModal = function (_a) {
    var hide = _a.hide, setFinder = _a.setFinder, refresh = _a.refresh, modifiedUser = _a.modifiedUser;
    var _b = __read(useState(modifiedUser), 2), user = _b[0], setUser = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var phoneRef = useRef(null);
    var create = function () {
        setLoading(true);
        API.updateAWSUser(user)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            hide();
            refresh();
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var validation = function () {
        if (user.name.trim() === '')
            return true;
        if (user.phone.trim() === '')
            return true;
        return false;
    };
    return (_jsxs(Dialog, __assign({ visible: true, header: translater("awsUsersItemUpdateModalDialogHeader"), style: { width: '50vw' }, onHide: function () { return hide(); } }, { children: [_jsx(OverlayPanel, __assign({ ref: phoneRef }, { children: translater("awsUsersItemCreateModalPhoneNumberFieldHint") })), _jsxs("div", __assign({ className: 'flex flex-column gap-2' }, { children: [_jsxs("div", __assign({ className: 'flex flex-column gap-1' }, { children: [_jsx("label", { children: translater("awsUsersItemUpdateModalNameField") }), _jsx(InputText, { value: user.name, placeholder: translater("awsUsersItemUpdateModalNameFieldPlaceholder").toString(), onChange: function (e) { return setUser(function (prev) { return (__assign(__assign({}, prev), { name: e.target.value })); }); } })] })), _jsxs("div", __assign({ className: 'flex flex-column gap-1' }, { children: [_jsxs("label", { children: [_jsx("i", { onClick: function (e) { var _a; return (_a = phoneRef.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" }), translater("awsUsersItemUpdateModalPhoneNumberField")] }), _jsx(InputText, { onChange: function (event) {
                                    var value = event.target.value;
                                    var regex = /^[0-9]*$/;
                                    if (regex.test(value)) {
                                        setUser(function (prev) { return (__assign(__assign({}, prev), { phone: event.target.value })); });
                                    }
                                }, value: user.phone, placeholder: translater("awsUsersItemUpdateModalPhoneNumberFieldPlaceholder").toString() })] })), _jsx(Button, { disabled: validation(), loading: loading, onClick: function () { return create(); }, label: translater("awsUsersItemUpdateModalUpdateButton").toString(), className: 'w-full p-button-success' })] }))] })));
};
