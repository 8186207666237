var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { Ripple } from "primereact/ripple";
import { InputText } from "primereact/inputtext";
import { NavLink } from "react-router-dom";
import { StyleClass } from "primereact/styleclass";
import { getTranslations, translater } from "../../../../../../../utils/localization/localization";
import { SignOutButton } from "./SignOutButton";
import { getAdminSettingsItems, getPBXSettingsItems, getSecuritySettingsItems, getSidebarItems, getSystemSettingsItems, getUserSettingsItems } from "../../../../../../../redux/actions/appActions";
import { adminPermission } from "../../../../../../../redux/actions/authActions";
export var NavigationTabPanel = function (_a) {
    var hideHeaderMenu = _a.hideHeaderMenu;
    var _b = __read(useState(true), 2), isPagesListOpen = _b[0], setPagesListOpen = _b[1];
    var _c = __read(useState(''), 2), inputValue = _c[0], setInputText = _c[1];
    var btnRef2 = useRef(null);
    var sidebarLinks = getSidebarItems();
    var adminLinks = getAdminSettingsItems();
    var systemSettingsLinks = getSystemSettingsItems();
    var pbxSettingsLinks = getPBXSettingsItems();
    var securitySettingsLinks = getSecuritySettingsItems();
    var userLinks = getUserSettingsItems();
    var links = __spreadArray(__spreadArray([], __read(sidebarLinks), false), __read(userLinks), false);
    if (adminPermission())
        links.push.apply(links, __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(adminLinks), false), __read(systemSettingsLinks), false), __read(pbxSettingsLinks), false), __read(securitySettingsLinks), false));
    return (_jsxs(_Fragment, { children: [_jsx(StyleClass, __assign({ nodeRef: btnRef2, selector: "@next", enterClassName: "hidden", leaveToClassName: "hidden" }, { children: _jsxs("a", __assign({ onClick: function () { return setPagesListOpen(!isPagesListOpen); }, ref: btnRef2, className: "p-ripple flex align-items-center cursor-pointer p-3 text-800 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1" }, { children: [_jsx("i", { className: "pi pi-cog mr-2" }), _jsx("span", __assign({ className: "font-medium" }, { children: translater("slideOverPagesListTitle") })), _jsx("i", { className: "pi pi-chevron-down ml-auto mr-1" }), _jsx(Ripple, {})] })) })), _jsxs("ul", __assign({ className: "m-0 list-none" }, { children: [_jsx("li", __assign({ className: "my-2" }, { children: _jsxs("span", __assign({ className: "p-input-icon-left w-full" }, { children: [_jsx("i", { className: "pi pi-search" }), _jsx(InputText, { value: inputValue, onChange: function (event) { return setInputText(event.target.value); }, type: "text", className: "border-none w-full", placeholder: translater("slideOverPagesListSearch").toString() })] })) })), inputValue === '' ? (_jsxs(_Fragment, { children: [_jsx("li", { children: _jsxs(NavLink, __assign({ to: "/", onClick: function () { return hideHeaderMenu(); }, className: "no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" }, { children: [_jsx("i", { className: 'pi pi-home mr-2' }), _jsx("span", __assign({ className: "font-medium" }, { children: translater("homeSideBar") })), _jsx(Ripple, {})] })) }), links
                                .sort(function (a, b) { return a.order - b.order; })
                                .map(function (link) {
                                return (_jsx("li", { children: _jsxs(NavLink, __assign({ onClick: function () { return hideHeaderMenu(); }, to: link.path, className: "no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full mt-1" }, { children: [_jsx("i", { className: link.classes + ' mr-2' }), _jsx("span", __assign({ className: "font-medium" }, { children: translater(link.name) })), _jsx(Ripple, {})] })) }, link.order));
                            })] })) : (links
                        .sort(function (a, b) { return a.order - b.order; })
                        .filter(function (link) { return getTranslations(link.name).join('').toLowerCase().includes(inputValue.toLowerCase()); })
                        .map(function (link) {
                        return (_jsx("li", { children: _jsxs(NavLink, __assign({ to: link.path, className: "no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full mt-1" }, { children: [_jsx("i", { className: link.classes + ' mr-2' }), _jsx("span", __assign({ className: "font-medium" }, { children: translater(link.name) })), _jsx(Ripple, {})] })) }, link.order));
                    }))] })), _jsx("ul", __assign({ className: "list-none border-top-1 surface-border p-0 m-0 mt-1" }, { children: _jsx("li", __assign({ className: "mt-1" }, { children: _jsx(SignOutButton, {}) })) }))] }));
};
